import React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"
import Thanks from "../components/thanks/Thanks"


const ThankYouPage = () => {

  return (
    <Layout className="thanksPage" >
      <Seo title="Dziękujemy za kontakt" />
      <Thanks />
    </Layout>
  )
}

export default ThankYouPage
